import { colorPalette } from "gx-npm-common-styles";

const dialogUpdateExistingSetupConfirmationStyles = {
  notificationActionButtons: {
    display: "flex",
    float: "right" as string as "right",
  },
  notificationDescription: {
    "& p": {
      paddingBottom: "20px",
    },
  },
  notificationSaveButton: {
    marginLeft: "24px",
  },
  notificationWrapper: {
    alignItems: "center",
    display: "flex",
  },
  notificationMessageTextArea: {
    "& .gx-notification-message-label": {
      color: colorPalette.neutrals.stone.hex,
      marginBottom: "8px",
    },
    "& .gx-base-textarea textarea": {
      width: "100%",
      "&::placeholder": {
        color: colorPalette.neutrals.stone.hex,
      },
    },
    "& .GCOM3606-gx-notification-message-label": {
      marginBottom: "8px",
    },
  },
};

export default dialogUpdateExistingSetupConfirmationStyles;
