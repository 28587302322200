import { Dispatch, SetStateAction } from "react";
import { UUID } from "gx-npm-lib";

// eslint-disable-next-line no-shadow
export enum SurveyUpdateType {
  NONE = "",
  NEW = "NEW",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export type CategoryType = {
  id: UUID;
  name: string;
  itemCount: number;
  items?: RequirementItemType[];
};
export type FormConfigType = { initId: UUID; surveyId: UUID };
export type RequestDocumentType = {
  id: UUID;
  index: number;
  isDeleted?: boolean;
  name: string;
  updateType?: SurveyUpdateType;
};
export type ResponseOptionType = {
  id: UUID;
  index: number;
  isDeleted?: boolean;
  name: string;
  updateType?: SurveyUpdateType;
};

export type SurveyPayloadType = {
  availableCategories: CategoryType[];
  companyName: string;
  hasSurveyRecipientViewed: boolean;
  instructions: string;
  requestedDocuments: RequestDocumentType[];
  responseOptions: ResponseOptionType[];
  selectedCategoryIds: UUID[];
};

export type FormContextValue = {
  availableCategories: CategoryType[];
  companyName: string;
  hasSurveyRecipientViewed: boolean;
  hasUpdates: boolean;
  instructions: string;
  isExistingSurvey: boolean;
  isNotifyingRecipients: boolean;
  notificationMessage: string;
  originalSelectedCategories: UUID[];
  requestedDocuments: RequestDocumentType[];
  responseOptions: ResponseOptionType[];
  selectedCategories: UUID[];
  setAvailableCategories: Dispatch<SetStateAction<CategoryType[]>>;
  setCompanyName: Dispatch<SetStateAction<string>>;
  setHasSurveyRecipientViewed: Dispatch<SetStateAction<boolean>>;
  setHasUpdates: Dispatch<SetStateAction<boolean>>;
  setInstructions: Dispatch<SetStateAction<string>>;
  setIsExistingSurvey: Dispatch<SetStateAction<boolean>>;
  setIsNotifyingRecipients: Dispatch<SetStateAction<boolean>>;
  setNotificationMessage: Dispatch<SetStateAction<string>>;
  setOriginalSelectedCategories: Dispatch<SetStateAction<UUID[]>>;
  setRequestedDocuments: Dispatch<SetStateAction<RequestDocumentType[]>>;
  setResponseOptions: Dispatch<SetStateAction<ResponseOptionType[]>>;
  setSelectedCategories: Dispatch<SetStateAction<UUID[]>>;
};

export type SurveyFormData = {
  companyName: string;
  instructions: string;
  requestedDocuments: RequestDocumentType[];
  responseOptions: ResponseOptionType[];
  selectedRequirementCategoryIds: UUID[];
};

export type RequirementCategoryDto = {
  id: UUID;
  name: string;
  items: RequirementItemType[];
  comment: string;
};

export type RequirementItemType = {
  id: UUID;
  name: string;
  comment: string;
  responseOptionId: UUID;
  description: string;
};

export type SurveyPreviewData = {
  companyName: string;
  instructions: string;
  requestedDocuments: RequestDocumentType[];
  responseOptions: ResponseOptionType[];
  isValid: boolean;
  requirementsCount: number;
  initiativeId: string;
  selectedCategories: RequirementCategoryDto[];
};
