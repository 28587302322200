import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorWhite = colorPalette.basic.white.hex;
const weightVarRegular = weightPalette.regular.variation;
const weightAmtRegular = weightPalette.regular.amount;

const companyNameInstructionsStyles = {
  container: {},
  textFieldContainer: {
    "& .gx-company-name .gx-text-field-container .MuiInputBase-multiline": {
      height: "auto",
      padding: "9px 65px 8px 12px",
      minHeight: 48,
    },
  },
  companyName: {
    backgroundColor: colorWhite,
    marginBottom: 56,
  },
  vendorInstructions: {
    "& textarea": {
      fontVariationSettings: weightVarRegular,
      fontWeight: weightAmtRegular,
      minHeight: 140,
      width: "100%",
    },
    "& .gx-base-textarea-helper-text": { fontSize: "16px" },
  },
};

export { companyNameInstructionsStyles };
