import React from "react";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "../viewing-responses/viewing-responses.styles.module.scss";
import ViewingResponsesImg from "../../../../assets/images/quick-start/viewingResponses.svg";

const ViewingResponsesComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TypographyComponent type={"h5"} boldness={"semi"}>
        {t("Viewing responses")}
      </TypographyComponent>
      <div className={styles.viewResponsesImageContainer}>
        <img src={ViewingResponsesImg} alt={t("Viewing responses")} />
      </div>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
          {t(
            "Once a vendor has completed your questionnaire, their responses, comments, and documents can be viewed in each vendor’s corresponding vendor workspace."
          )}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
          {t("Each time a vendor shares their responses, you will be notified by email.")}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about viewing questionnaire responses")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/8226190-viewing-buysmart-questionnaire-responses"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ViewingResponsesComponent;
