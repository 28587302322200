import { UUID } from "gx-npm-lib";
import { useFormContext } from "../form.context";
import {
  addRequestedDocument,
  removeRequestedDocumentFromExistingSurvey,
  removeRequestedDocumentFromNewSurvey,
  updateRequestedDocumentName,
} from "./form-requested-documents.lib";

const useFormRequestedDocuments = () => {
  const { isExistingSurvey, requestedDocuments, setRequestedDocuments, setHasUpdates } = useFormContext();

  const handleAddRequestedDocument = () => {
    setRequestedDocuments((prev) => addRequestedDocument(prev));
    setHasUpdates(true);
  };

  const handleRemoveRequestedDocument = (id: UUID) => {
    const handler = isExistingSurvey ? removeRequestedDocumentFromExistingSurvey : removeRequestedDocumentFromNewSurvey;
    setRequestedDocuments((prev) => handler(prev, id));
    setHasUpdates(true);
  };

  const handleUpdateRequestedDocumentName = (id: UUID, name: string) => {
    setRequestedDocuments((prev) => updateRequestedDocumentName(prev, id, name));
    setHasUpdates(true);
  };

  return {
    handleAddRequestedDocument,
    handleRemoveRequestedDocument,
    handleUpdateRequestedDocumentName,
    requestedDocuments,
  };
};

export default useFormRequestedDocuments;
