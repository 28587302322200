import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IconMessage } from "../../../../ui/icons/form.icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { useFormContext } from "../../context/form.context";
import FormSection from "../form-section/form-section.component";
import FormSectionAbout from "../form-section/form-section-about.component";
import FormSectionLeft from "../form-section/form-section-left.component";
import FormSectionRight from "../form-section/form-section-right.component";
import FormSectionStatus from "../form-section/form-section-status.component";
import styles from "./response-options.styles";
import ResponseOptionsList from "./response-options-list.component";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
const ResponseOptionsComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { responseOptions } = useFormContext();

  return (
    <div aria-label={t("custom response options")}>
      <FormSection>
        <FormSectionLeft>
          <FormSectionStatus isComplete={responseOptions.length > 0} />
          <FormSectionAbout
            title={t("Customize response options")}
            description={t(
              "Manage how you want recipients to respond to each requirement (e.g. fully supports, partially supports, doesn't support)."
            )}
          />
          <div className={classes.divider} />
          <div className={classes.infoRoot}>
            <div>
              <IconMessage />
            </div>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent element={"p"} color={"sweetGrape"}>
                  {t(
                    "Recipients will have the ability to leave an optional, short text response for each requirement."
                  )}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <Paragraph rootClassName={classes.info}>
                  {t(
                    "Recipients will have the ability to leave an optional, short text response for each requirement."
                  )}
                </Paragraph>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        </FormSectionLeft>
        <FormSectionRight>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                boldness={"semi"}
                element={"p"}
                color={"coal"}
                rootClassName={classNames(classes.GCOM3606responseOptions)}
              >
                {t("Response options")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph boldness="semi" rootClassName={classes.responseOptions}>
                {t("Response options")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <ResponseOptionsList />
        </FormSectionRight>
      </FormSection>
    </div>
  );
};

export default ResponseOptionsComponent;
