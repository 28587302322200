import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { BaseTextArea, TextField } from "gx-npm-ui";
import { FormEvent, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { companyNameInstructionsStyles as styles } from "./company-name-instructions.styles";
import useFormTextFields from "../../context/use-form-text-fields.hook";

const useStyles = makeStyles(() => styles);
const CompanyNameInstructionsFieldsComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyName, handleChangeCompanyName, handleChangeInstructions, instructions } = useFormTextFields();

  return (
    <div aria-label={t("company name and vendor instructions input fields")}>
      <div aria-label="textbox" className={classes.textFieldContainer}>
        <TextField
          label={t("Your company name?")}
          maxAllowedChars={60}
          multiline={true}
          onChange={(e: FormEvent<HTMLInputElement>) => handleChangeCompanyName(e.currentTarget.value)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          required={true}
          rootClassName={classNames(classes.companyName, "gx-company-name")}
          showCharCount={true}
          showLabel={true}
          value={companyName}
        />
      </div>
      <BaseTextArea
        flavor="OUTLINED"
        helperText={t("Instructions for vendors")}
        onChange={(e: FormEvent<HTMLInputElement>) => handleChangeInstructions(e.currentTarget.value)}
        placeholder={t("Instructions for vendors")}
        required={true}
        rootClassName={classes.vendorInstructions}
        showVerticalResize={true}
        value={instructions}
      />
    </div>
  );
};

export default CompanyNameInstructionsFieldsComponent;
