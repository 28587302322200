import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./app.component";
import React from "react";
import HandleRedirects from "./handle-redirects";
import { FormContextProvider } from "./sections/form/context/form.context";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <FormContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path={"/s/evaluation/:initiativeId/questionnaire/setup"} element={<App />} />
            <Route path={"/s/evaluation/:initiativeId/questionnaire/setup/:surveyId"} element={<App />} />
            <Route path={"*"} element={<HandleRedirects />} />
          </Routes>
        </BrowserRouter>
      </FormContextProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
