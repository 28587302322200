import * as queryString from "query-string";
import * as singleSpa from "single-spa";

/**
 * @description :: will accept two URL formats
 * 1. /s/questionnaire-builder?initId=:initId&surveyId=:surveyId
 * 2. /s/questionnaire-builder?initId=:initId
 */
const HandleRedirects = () => {
  const { search } = window.location;
  const defaultRoute = "/s/evaluations";
  const { initId, surveyId } = queryString.parse(search);
  if (initId && surveyId) {
    singleSpa.navigateToUrl(`/s/evaluation/${initId}/questionnaire/setup/${surveyId}${search}`);
  } else if (initId && !surveyId) {
    singleSpa.navigateToUrl(`/s/evaluation/${initId}/questionnaire/setup${search}`);
  } else {
    singleSpa.navigateToUrl(defaultRoute);
  }
  return null;
};

export default HandleRedirects;
