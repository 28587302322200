import { useEffect, useState } from "react";
import { UUID } from "gx-npm-lib";
import { useFormContext } from "./form.context";

const useFormSelectedCategories = () => {
  const {
    availableCategories,
    isExistingSurvey,
    originalSelectedCategories,
    selectedCategories,
    setHasUpdates,
    setSelectedCategories,
  } = useFormContext();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [totalCategoriesCount, setTotalCategoriesCount] = useState(0);
  const [totalRequirementsCount, setTotalRequirementsCount] = useState(0);

  useEffect(() => {
    setHasUpdates(JSON.stringify(selectedCategories.sort()) !== JSON.stringify(originalSelectedCategories.sort()));
    setIsAllSelected(availableCategories.length === selectedCategories.length);
    setTotalCategoriesCount(selectedCategories.length);
    setTotalRequirementsCount(
      availableCategories.reduce((acc, category) => {
        if (!selectedCategories.find((id) => category.id === id)) {
          return acc;
        }
        return acc + category.itemCount;
      }, 0)
    );
  }, [availableCategories, isExistingSurvey, originalSelectedCategories, selectedCategories, setHasUpdates]);

  const handleChangeSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedCategories(availableCategories.map((category) => category.id));
    } else {
      setSelectedCategories([]);
    }
  };

  const handleChangeCategorySelected = (selectedId: UUID) => {
    if (verifyIsCategorySelected(selectedId)) {
      setSelectedCategories((prevSelectedCategories) => {
        return prevSelectedCategories.filter((id) => selectedId !== id);
      });
    } else {
      setSelectedCategories((prevSelectedCategories) => [...prevSelectedCategories, selectedId]);
    }
    setHasUpdates(JSON.stringify(selectedCategories.sort()) !== JSON.stringify(originalSelectedCategories.sort()));
  };

  const verifyIsCategorySelected = (selectedId: UUID) => {
    return !!selectedCategories.find((id) => selectedId === id);
  };

  return {
    availableCategories,
    handleChangeCategorySelected,
    handleChangeSelectAll,
    isAllSelected,
    totalCategoriesCount,
    totalRequirementsCount,
    verifyIsCategorySelected,
  };
};

export default useFormSelectedCategories;
