import { colorPalette } from "gx-npm-common-styles";

const colorBlue = colorPalette.brand.gartnerBlue.hex;
const colorWhite = colorPalette.basic.white.hex;

const headerStyles = {
  root: {
    backgroundColor: colorBlue,
    display: "flex" as const,
    padding: "48px 56px",
    "& $title": {
      color: colorWhite,
      paddingBottom: "8px",
    },
    "& $linkPlay": {
      alignItems: "center",
      display: "flex" as const,
      marginLeft: "8px",
      "&.gx-link-btn": {
        backgroundColor: "unset",
        border: "unset",
        "&:hover": {
          cursor: "pointer",
          textDecoration: `underline ${colorWhite}`,
        },
      },
      "& svg": {
        marginRight: "6px",
      },
      "& p": {
        color: colorWhite,
      },
    },
    "& $process": {
      display: "flex" as const,
      "& $step": {
        display: "flex" as const,
        marginRight: "48px",
        "& $stepImageWrapper": {
          alignItems: "center",
          display: "flex" as const,
          height: "70px",
        },
        "& p": {
          color: colorWhite,
          marginTop: "15px",
        },
        "&:first-child": {
          marginLeft: "139px",
        },
        "& $arrow": {
          marginBottom: "36px",
          marginLeft: "44px",
        },
      },
    },
  },
  arrow: {},
  linkPlay: {},
  process: {},
  step: {},
  stepImageWrapper: {},
  title: {},
};

export default headerStyles;
