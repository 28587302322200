import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  BaseTextArea,
  Button,
  ButtonLoader,
  Dialog,
  Switch,
  Paragraph,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  FeatureFlagBooleanOff,
  TypographyComponent,
} from "gx-npm-ui";
import React, { FormEvent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../form/context/form.context";
import styles from "./dialog-update-existing-setup-confirmation.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const DialogUpdateExistingSetupConfirmation = ({
  isOpen = false,
  isSaving = false,
  onClose = (_isConfirmed: boolean) => {
    /*do nothing*/
  },
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isNotifyingRecipients, notificationMessage, setIsNotifyingRecipients, setNotificationMessage } =
    useFormContext();
  return (
    <Dialog
      body={
        <Fragment>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <div className={classes.notificationDescription}>
                <TypographyComponent styling={"p2"}>
                  {t(
                    "Because you have already shared this questionnaire with vendors, your changes may impact their existing responses."
                  )}
                </TypographyComponent>
                <TypographyComponent styling={"p2"}>
                  {t("We recommend that you let vendors know what changed by opting to notify them.")}
                </TypographyComponent>
              </div>
              <div className={classes.notificationWrapper}>
                <Switch checked={isNotifyingRecipients} onToggle={() => setIsNotifyingRecipients((prev) => !prev)} />
                <TypographyComponent styling={"p3"}>
                  {t("Send a notification email to questioned vendors")}
                </TypographyComponent>
              </div>

              {isNotifyingRecipients && (
                <div className={classes.notificationMessageTextArea}>
                  <TypographyComponent
                    styling={"p4"}
                    rootClassName={"GCOM3606-gx-notification-message-label"}
                    color={"stone"}
                  >
                    {t("Comments to be included in the email.")}
                  </TypographyComponent>
                  <BaseTextArea
                    flavor="OUTLINED"
                    onChange={(event: FormEvent<HTMLInputElement>) =>
                      setNotificationMessage(event.currentTarget.value || "")
                    }
                    placeholder={t("Tell the vendor what changed...")}
                    value={notificationMessage}
                  />
                </div>
              )}
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <div className={classes.notificationDescription}>
                <Paragraph type="p2">
                  {t(
                    "Because you have already shared this questionnaire with vendors, your changes may impact their existing responses."
                  )}
                </Paragraph>
                <Paragraph type="p2">
                  {t("We recommend that you let vendors know what changed by opting to notify them.")}
                </Paragraph>
              </div>
              <div className={classes.notificationWrapper}>
                <Switch checked={isNotifyingRecipients} onToggle={() => setIsNotifyingRecipients((prev) => !prev)} />
                <Paragraph type="p3">{t("Send a notification email to questioned vendors")}</Paragraph>
              </div>

              {isNotifyingRecipients && (
                <div className={classes.notificationMessageTextArea}>
                  <Paragraph rootClassName="gx-notification-message-label" type="p4">
                    {t("Comments to be included in the email.")}
                  </Paragraph>
                  <BaseTextArea
                    flavor="OUTLINED"
                    onChange={(event: FormEvent<HTMLInputElement>) =>
                      setNotificationMessage(event.currentTarget.value || "")
                    }
                    placeholder={t("Tell the vendor what changed...")}
                    value={notificationMessage}
                  />
                </div>
              )}
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </Fragment>
      }
      open={isOpen}
      title={t("Confirm your changes")}
      footer={
        <div className={classes.notificationActionButtons}>
          <Button
            onClick={() => onClose(false)}
            rootClassName={classNames("gx-btn-update-existing-setup-cancel", "btn-tertiary")}
          >
            {t("Cancel")}
          </Button>
          <div className={classes.notificationSaveButton}>
            <ButtonLoader
              ariaLabel={t("save changes")}
              btnClass="btn-primary"
              btnRootClassName="gx-btn-update-existing-setup-confirm"
              disabled={isNotifyingRecipients && !notificationMessage}
              isLoading={isSaving}
              onClick={() => onClose(true)}
            >
              {t("Save setup")}
            </ButtonLoader>
          </div>
        </div>
      }
    />
  );
};

export default DialogUpdateExistingSetupConfirmation;
