import { makeStyles } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "gx-npm-icons";
import { MiniButton } from "gx-npm-ui";
import { UUID } from "gx-npm-lib";
import DragDropList from "../../../../ui/drag-drop-list/drag-drop-list.component";
import DragDropListItemDeleteComponent from "../../../../ui/drag-drop-list/drag-drop-list-item-delete.component";
import DragDropListItem from "../../../../ui/drag-drop-list/drag-drop-list-item.component";
import DragDropListItemTextAreaComponent from "../../../../ui/drag-drop-list/drag-drop-list-item-textarea.component";
import { IconDocument } from "../../../../ui/icons/form.icons";
import useFormRequestedDocuments from "../../context/requested-documents/use-form-requested-documents.hook";
import { requestDocumentsListStyles as styles } from "./request-documents-list.styles";

const useStyles = makeStyles(() => styles);
const RequestDocumentsList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLastItemFocused, setIsLastItemFocused] = useState(false);
  const {
    handleAddRequestedDocument,
    handleRemoveRequestedDocument,
    handleUpdateRequestedDocumentName,
    requestedDocuments,
  } = useFormRequestedDocuments();
  const requestedDocumentsCount = requestedDocuments.filter((doc) => !doc.isDeleted).length;

  const handleAddRequestDocument = () => {
    handleAddRequestedDocument();
    setIsLastItemFocused(true);
  };

  const handleBlurTextArea = (id: UUID, value: string) => {
    handleUpdateRequestedDocumentName(id, value);
    setIsLastItemFocused(false);
  };

  return (
    <div aria-label={t("requested documents list")} className={classes.root}>
      <DragDropList itemList={requestedDocuments} rootClassName={"request-documents-list"}>
        {requestedDocuments.map((document, index) => {
          return !document.isDeleted ? (
            <DragDropListItem
              draggable={false}
              id={document.id}
              index={document.index}
              key={document.id}
              renderItem={(params: { hover: boolean; setEdit: (isEdit: boolean) => void }) => {
                return (
                  <Fragment>
                    <div className={classes.iconDocument}>
                      <IconDocument />
                    </div>
                    <DragDropListItemTextAreaComponent
                      hover={params.hover}
                      isLastItemFocused={index === requestedDocumentsCount - 1 && isLastItemFocused}
                      onBlur={(e) => handleBlurTextArea(document.id, e.currentTarget.value)}
                      placeholder={t("Enter requested document here")}
                      setEdit={params.setEdit}
                      value={document.name}
                    />
                    <DragDropListItemDeleteComponent
                      hover={params.hover}
                      onClick={() => handleRemoveRequestedDocument(document.id)}
                    />
                  </Fragment>
                );
              }}
            />
          ) : (
            <Fragment key={document.id} />
          );
        })}
      </DragDropList>
      <MiniButton onClick={handleAddRequestDocument}>
        <PlusIcon />
        {t("Add document request")}
      </MiniButton>
    </div>
  );
};

export default RequestDocumentsList;
