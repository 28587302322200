import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { IconButton } from "gx-npm-ui";
import { colorPalette } from "gx-npm-common-styles";
import { TrashcanIcon } from "gx-npm-icons";
import { listItemDeleteButtonStyles as styles } from "./drag-drop-list.styles";

const useStyles = makeStyles(() => styles);

type DragDropListItemDeleteComponentProps = { hover?: boolean; onClick: () => void };

const DragDropListItemDeleteComponent = ({ hover = false, onClick }: DragDropListItemDeleteComponentProps) => {
  const classes = useStyles();

  const colorIron = colorPalette.neutrals.iron.hex;
  const colorPoisonCherry = colorPalette.status.poisonCherry.hex;

  return (
    <div aria-label="drag and drop item delete" className={classNames("gx-dlt-btn", classes.root)}>
      <IconButton hoverFill={colorPoisonCherry} onClick={onClick} transparentBackground>
        <TrashcanIcon fillPath={hover ? colorIron : "none"} />
      </IconButton>
    </div>
  );
};

export default DragDropListItemDeleteComponent;
