import { FormConfigType, RequestDocumentType, ResponseOptionType, SurveyUpdateType } from "../../form.types";
import { getAsyncRequest, isValidResponse } from "gx-npm-lib";

/**
 * load form data from API
 * @param formConfig
 * @param onSuccessResponse will always be invoked with default values if API response lacks
 * @param onErrorResponse invoked when API response is invalid
 */
const loadFormData = async (
  formConfig: FormConfigType,
  onSuccessResponse: (data: object) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { initId, surveyId } = formConfig;
  const url = `/api/v2/initiatives/${initId}/survey/set-up/${surveyId}`;
  const response = await getAsyncRequest(url);
  if (isValidResponse(response) && typeof response.data.data === "object") {
    const { data } = response.data;
    onSuccessResponse({
      availableCategories: data.availableCategories || [],
      companyName: data.companyName || "",
      hasSurveyRecipientViewed: data.hasSurveyRecipientViewed || false,
      instructions: data.instructions || "",
      isExistingSurvey: !!surveyId,
      selectedCategoryIds: data.selectedCategoryIds || [],
      responseOptions: (data.responseOptions || [])
        .map((option: ResponseOptionType) => ({ ...option, updateType: SurveyUpdateType.NONE }))
        .sort((a: ResponseOptionType, b: ResponseOptionType) => (a.index > b.index ? 1 : -1)),
      requestedDocuments: (data.requestedDocuments || [])
        .map((document: RequestDocumentType) => ({ ...document, updateType: SurveyUpdateType.NONE }))
        .sort((a: RequestDocumentType, b: RequestDocumentType) => (a.index > b.index ? 1 : -1)),
    });
  } else {
    onErrorResponse(response);
  }
};

export { loadFormData };
