import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import headerProcessArrow from "../../assets/images/header-process-arrow.svg";
import headerSendToVendor from "../../assets/images/header-process-send-to-vendor.svg";
import headerSetUpSurvey from "../../assets/images/header-process-set-up-survey.svg";
import headerViewResponses from "../../assets/images/header-process-view-responses.svg";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  QuickStartPopperComponent,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./header.styles";
import OverviewComponent from "./quick-start/overview/overview.component";
import CreatingTheQuestionnaireComponent from "./quick-start/creating-the-questionnaire/creating-the-questionnaire.component";
import SendingTheQuestionnaireComponent from "./quick-start/sending-the-questionnaire/sending-the-questionnaire.component";
import ViewingResponsesComponent from "./quick-start/viewing-responses/viewing-responses.component";
import { ClientEvent } from "../../app.constants";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { useParams } from "react-router-dom";
import { InfoLightIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const HeaderComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initiativeId } = useParams();
  const [isSurveyTutorialDrawerOpen, setIsSurveyTutorialDrawerOpen] = useState(false);
  const captureEvents = useCaptureEventsV2();
  const clientEvents = [
    ClientEvent.SURVEY_SETUP_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvent.SURVEY_SETUP_QUICKSTART_CREATING_QUESTIONNAIRE_CLICKED,
    ClientEvent.SURVEY_SETUP_QUICKSTART_SENDING_QUESTIONNAIRE_CLICKED,
    ClientEvent.SURVEY_SETUP_QUICKSTART_VIEWING_RESPONSE_CLICKED,
  ];
  const quickStartTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Creating the questionnaire", content: <CreatingTheQuestionnaireComponent /> },
    { label: "Sending the questionnaire", content: <SendingTheQuestionnaireComponent /> },
    { label: "Viewing Responses", content: <ViewingResponsesComponent /> },
  ];

  const metaData = { initiativeId: initiativeId || "" };

  const handleClickSurveyTutorial = () => {
    captureEvents([
      {
        eventType: ClientEvent.SURVEY_SETUP_QUICKSTART_MODAL_OPENED,
        metaData,
      },
    ]);
    setIsSurveyTutorialDrawerOpen(true);
  };

  const handleCloseSurveyTutorial = () => {
    captureEvents([
      {
        eventType: ClientEvent.SURVEY_SETUP_QUICKSTART_MODAL_CLOSED,
        metaData,
      },
    ]);
    setIsSurveyTutorialDrawerOpen(false);
  };

  const handleEventCapture = (tabIndex: number) => {
    captureEvents([
      {
        eventType: clientEvents[tabIndex],
        metaData,
      },
    ]);
  };

  return (
    <section aria-label="Header" className={classes.root}>
      <div>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={classes.title} boldness={"medium"} styling="h2">
              {t("Customize your questionnaire")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={classes.title} styling="h2">
              {t("Customize your questionnaire")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>

        <button
          aria-label={"questionnaire-info"}
          className={classNames("gx-link-btn", classes.linkPlay)}
          onClick={handleClickSurveyTutorial}
        >
          <InfoLightIcon fillPath={colorPalette.basic.white.hex} />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness="medium" styling="p3">
                {t("How questionnaires work")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="semi" styling="p3">
                {t("How questionnaires work")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </button>
      </div>
      <div className={classes.process}>
        <div className={classes.step}>
          <div>
            <div className={classes.stepImageWrapper}>
              <img alt="" src={headerSetUpSurvey} />
            </div>
            <TypographyComponent styling="p4">{t("Set up questionnaire")}</TypographyComponent>
          </div>
          <img alt="" className={classes.arrow} src={headerProcessArrow} />
        </div>
        <div className={classes.step}>
          <div>
            <div className={classes.stepImageWrapper}>
              <img alt="" src={headerSendToVendor} />
            </div>
            <TypographyComponent styling="p4">{t("Send to vendor")}</TypographyComponent>
          </div>
          <img alt="" className={classes.arrow} src={headerProcessArrow} />
        </div>
        <div className={classes.step}>
          <div>
            <div className={classes.stepImageWrapper}>
              <img alt="" src={headerViewResponses} />
            </div>
            <TypographyComponent styling="p4">{t("View responses")}</TypographyComponent>
          </div>
        </div>
      </div>
      <QuickStartPopperComponent
        title={t("Vendor questionnaire")}
        isOpen={isSurveyTutorialDrawerOpen}
        onClose={handleCloseSurveyTutorial}
        tabsData={quickStartTabsData}
        onTabClick={handleEventCapture}
      />
    </section>
  );
};

export default HeaderComponent;
