import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useFormSelectedCategories from "../../context/use-form-selected-categories.hook";
import FormSection from "../form-section/form-section.component";
import FormSectionAbout from "../form-section/form-section-about.component";
import FormSectionLeft from "../form-section/form-section-left.component";
import FormSectionRight from "../form-section/form-section-right.component";
import FormSectionStatus from "../form-section/form-section-status.component";
import { questionsCategoriesStyles as styles } from "./questions-categories.styles";
import QuestionsCategoriesListComponent from "./questions-categories-list.component";

const useStyles = makeStyles(() => styles);
const QuestionsCategoriesComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { totalCategoriesCount } = useFormSelectedCategories();

  return (
    <div aria-label={t("categories of questions to add to questionnaire")}>
      <FormSection rootClassName={classes.container}>
        <FormSectionLeft>
          <FormSectionStatus isComplete={totalCategoriesCount > 0} />
          <FormSectionAbout
            description={t(
              "Choose which categories of questions you want recipients to respond to. You can come back and edit this later."
            )}
            title={t("Select questions to add to your questionnaire")}
          />
        </FormSectionLeft>
        <FormSectionRight>
          <QuestionsCategoriesListComponent />
        </FormSectionRight>
      </FormSection>
    </div>
  );
};

export default QuestionsCategoriesComponent;
