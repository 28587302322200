const formSectionStyles = {
  container: { display: "flex", marginBottom: 124 },
  left: {
    display: "flex",
    flexDirection: "column" as string as "column",
    marginLeft: 48,
    marginRight: 76,
    width: 439,
  },
  right: {
    width: 600,
  },
};

export { formSectionStyles };
