import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "gx-npm-icons";
import { MiniButton } from "gx-npm-ui";
import { UUID } from "gx-npm-lib";
import DragDropList from "../../../../ui/drag-drop-list/drag-drop-list.component";
import DragDropListItem from "../../../../ui/drag-drop-list/drag-drop-list-item.component";
import DragDropListItemDeleteComponent from "../../../../ui/drag-drop-list/drag-drop-list-item-delete.component";
import DragDropListItemTextAreaComponent from "../../../../ui/drag-drop-list/drag-drop-list-item-textarea.component";
import useFormResponseOptions from "../../context/response-options/use-form-response-options.hook";
import styles from "./response-options-list.styles";

const useStyles = makeStyles(() => styles);
const ResponseOptionsList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLastItemFocused, setIsLastItemFocused] = useState(false);
  const {
    handleAddResponseOption,
    handleMoveResponseOption,
    handleRemoveResponseOption,
    handleUpdateResponseOptionName,
    responseOptions,
  } = useFormResponseOptions();

  const handleAddResponse = () => {
    handleAddResponseOption();
    setIsLastItemFocused(true);
  };

  const handleBlurTextArea = (id: UUID, value: string) => {
    handleUpdateResponseOptionName(id, value);
    setIsLastItemFocused(false);
  };

  return (
    <div aria-label={t("response options list")}>
      <DragDropList itemList={responseOptions} onDragFinish={handleMoveResponseOption} rootClassName={classes.root}>
        {responseOptions.map((option, index) =>
          !option.isDeleted ? (
            <DragDropListItem
              id={option.id}
              index={index}
              key={option.id}
              renderItem={(params: { hover: boolean; setEdit: (isEdit: boolean) => void }) => {
                return (
                  <Fragment>
                    <DragDropListItemTextAreaComponent
                      hover={params.hover}
                      maxAllowedChars={70}
                      isLastItemFocused={
                        index === responseOptions.filter((opt) => !opt.isDeleted).length - 1 && isLastItemFocused
                      }
                      onBlur={(e) => handleBlurTextArea(option.id, e.currentTarget.value)}
                      setEdit={params.setEdit}
                      showCharCountOnFocus={true}
                      showCharCountOnHover={true}
                      value={option.name}
                    />
                    <DragDropListItemDeleteComponent
                      hover={params.hover}
                      onClick={() => handleRemoveResponseOption(option.id)}
                    />
                  </Fragment>
                );
              }}
            />
          ) : (
            <Fragment key={option.id} />
          )
        )}
      </DragDropList>
      <MiniButton onClick={handleAddResponse}>
        <PlusIcon />
        {t("Add response option")}
      </MiniButton>
    </div>
  );
};

export default ResponseOptionsList;
