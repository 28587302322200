const dialogConfirmCancelStyles = {
  btnContainer: {
    display: "flex",
    "& button": {
      marginLeft: "24px",
      marginRight: "0",
      "&:nth-child(1)": {
        marginLeft: "auto",
      },
    },
  },
};

export default dialogConfirmCancelStyles;
