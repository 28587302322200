import { makeStyles } from "@material-ui/core";
import { formSectionStyles as styles } from "./form-section.styles";

type FormSectionRightProps = {
  children?: Array<React.ReactNode> | React.ReactNode | number | string | null;
};

const useStyles = makeStyles(() => styles);

const FormSectionRight = ({ children }: FormSectionRightProps) => {
  const classes = useStyles();
  return <div className={classes.right}>{children}</div>;
};

export default FormSectionRight;
