const requestDocumentsListStyles = {
  root: {
    "& .request-documents-list": {
      marginBottom: 24,
    },
  },
  iconDocument: {
    height: 48,
    width: 48,
  },
};

export { requestDocumentsListStyles };
