import { UUID } from "gx-npm-lib";
import { ResponseOptionType, SurveyUpdateType } from "../../form.types";
import { v4 as uuidV4 } from "uuid";

export const addResponseOption = (prevResponseOptions: ResponseOptionType[]) => {
  const newOption: ResponseOptionType = {
    id: uuidV4() as UUID,
    index: prevResponseOptions.filter((opt) => !opt.isDeleted).length,
    name: "",
    updateType: SurveyUpdateType.NEW,
  };
  return [...prevResponseOptions, newOption];
};

export const moveResponseOptions = (moveResults: ResponseOptionType[]) => {
  let currentIndex = 0;
  return moveResults.map((responseOption) => {
    if (responseOption.isDeleted) {
      return responseOption;
    }
    const { index } = responseOption;
    if (index !== currentIndex) {
      responseOption.updateType = responseOption.updateType || SurveyUpdateType.UPDATE;
    }
    return { ...responseOption, index: currentIndex++ };
  });
};

export const removeResponseOptionFromExistingSurvey = (
  prevResponseOptions: ResponseOptionType[],
  id: UUID
): ResponseOptionType[] => {
  let currentIndex = 0;
  return prevResponseOptions
    .map((responseOption) => {
      if (responseOption.isDeleted) {
        return responseOption;
      }
      if (responseOption.id === id) {
        const isNew = responseOption.updateType === SurveyUpdateType.NEW;
        const updateType = isNew ? SurveyUpdateType.NEW : SurveyUpdateType.DELETE;
        return { ...responseOption, isDeleted: true, updateType };
      }
      if (responseOption.index !== currentIndex) {
        responseOption.updateType = responseOption.updateType || SurveyUpdateType.UPDATE;
      }
      return { ...responseOption, index: currentIndex++ };
    })
    .filter((responseOption) => {
      return !responseOption.isDeleted || responseOption.updateType !== SurveyUpdateType.NEW;
    });
};

export const removeResponseOptionFromNewSurvey = (prevResponseOptions: ResponseOptionType[], id: UUID) => {
  return prevResponseOptions
    .filter((responseOption) => responseOption.id !== id)
    .map((responseOption, index) => ({ ...responseOption, index }));
};

export const updateResponseOptionName = (prevResponseOptions: ResponseOptionType[], id: UUID, name: string) => {
  return prevResponseOptions.map((responseOption) => {
    if (responseOption.id !== id) {
      return responseOption;
    }
    const updateType = responseOption.updateType || SurveyUpdateType.UPDATE;
    return { ...responseOption, name, updateType };
  });
};
