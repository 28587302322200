import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { handleEvent, isValidNumber } from "gx-npm-lib";
import { DragDropContext, DragStart, Droppable, DroppableProvided, DropResult } from "react-beautiful-dnd";
import { dragAndDropRootStyles as styles } from "./drag-drop-list.styles";

type DragDropListProps = {
  children?: React.ReactElement | Array<React.ReactElement> | string | null;
  draggable?: boolean;
  itemList?: Array<object>;
  onDragComplete?: () => void;
  onDragFinish?: (result: unknown) => void;
  onDragStart?: () => void;
  rootClassName?: string;
};

const useStyles = makeStyles(() => styles);
/**
 * callback props passed into container
 * @param {*} children - the list of items to be rendered
 * @param {*} draggable - is the list item draggable
 * @param {*} itemList - the list of items to be rendered
 * @param {*} onDragComplete - fires after a drag event has completed and any changes have already been emitted
 * @param {*} onDragFinish - fires when a drag event finishes with changes in order of list
 * @param {*} onDragStart - fires when a drag even begins
 * @param {*} rootClassName - the root class name for the container
 * @returns
 */
const DragDropList = ({
  children = null,
  draggable = true,
  itemList,
  onDragComplete,
  onDragFinish,
  onDragStart,
  rootClassName = "",
}: DragDropListProps) => {
  const classes = useStyles();

  const handleOnDragEnd = (result: DropResult) => {
    if (
      Array.isArray(itemList) &&
      isValidNumber(result?.destination?.index) &&
      isValidNumber(result?.source?.index) &&
      result?.destination?.index !== result?.source?.index &&
      result?.destination?.index !== undefined
    ) {
      const items = Array.from(itemList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      handleEvent(onDragFinish, items, result);
    }
    handleEvent(onDragComplete, result);
  };

  const handleOnDragStart = (event: DragStart) => {
    handleEvent(onDragStart, event);
  };

  const renderListItem = (provided?: DroppableProvided | undefined) => {
    return (
      <ul
        className={classNames("gx-dnd-unordered-dropzone", classes.listItems)}
        {...(provided && { ref: provided.innerRef, ...provided.droppableProps })}
      >
        {children}
        {provided && provided.placeholder}
      </ul>
    );
  };

  const renderDraggable = () => {
    return (
      <DragDropContext onDragEnd={(event) => handleOnDragEnd(event)} onDragStart={(event) => handleOnDragStart(event)}>
        <Droppable droppableId="itemList">{(provided) => renderListItem(provided)}</Droppable>
      </DragDropContext>
    );
  };

  return (
    <section className={classNames("gx-dnd-dropzone-root", rootClassName)}>
      {draggable ? renderDraggable() : renderListItem()}
    </section>
  );
};

export default DragDropList;
