import { TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "../creating-the-questionnaire/creating-the-questionnaire.styles.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import CreateQuestionnaireImg from "../../../../assets/images/quick-start/createQuestionnaire.svg";

const CreatingTheQuestionnaireComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TypographyComponent type={"h5"} boldness={"semi"}>
        {t("Creating the questionnaire")}
      </TypographyComponent>
      <div className={styles.mediaContainer}>
        <img src={CreateQuestionnaireImg} alt={t("Creating the questionnaire")} />
      </div>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
          {t(
            "The requirements you specify will serve as the core of your questionnaire, but other customization options are available."
          )}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
          {t("Other options include:")}
        </TypographyComponent>
        <ul className={styles.link}>
          <li>
            <TypographyComponent type={"p2"} boldness={"regular"}>
              {t("Customizable response options for your requirements")}
            </TypographyComponent>
          </li>
          <li>
            <TypographyComponent type={"p2"} boldness={"regular"}>
              {t("Instructions for recipients")}
            </TypographyComponent>
          </li>
          <li>
            <TypographyComponent type={"p2"} boldness={"regular"}>
              {t("Request additional documents to be uploaded by the vendor")}
            </TypographyComponent>
          </li>
        </ul>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about setting up questionnaires")}
            target={"_blank"}
            href={"https://intercom.help/gartner-buysmart-help-center/en/articles/8219790-buysmart-questionnaire-setup"}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatingTheQuestionnaireComponent;
