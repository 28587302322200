import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../../context/form.context";
import FormSection from "../form-section/form-section.component";
import FormSectionLeft from "../form-section/form-section-left.component";
import FormSectionRight from "../form-section/form-section-right.component";
import FormSectionAbout from "../form-section/form-section-about.component";
import FormSectionStatus from "../form-section/form-section-status.component";
import CompanyNameInstructionsFieldsComponent from "./company-name-instructions-fields.component";
import { companyNameInstructionsStyles as styles } from "./company-name-instructions.styles";

const useStyles = makeStyles(() => styles);
const CompanyNameInstructionsComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { companyName, instructions } = useFormContext();

  return (
    <div aria-label={t("company name and vendor instructions")}>
      <FormSection rootClassName={classes.container}>
        <FormSectionLeft>
          <FormSectionStatus isComplete={!!companyName && !!instructions} />
          <FormSectionAbout
            description={t(
              "Provide clear instructions for your questionnaire. This will be shared with all questionnaire recipients. You can come back and edit this later."
            )}
            title={t("Add your company name and provide instructions for the vendor")}
          />
        </FormSectionLeft>
        <FormSectionRight>
          <CompanyNameInstructionsFieldsComponent />
        </FormSectionRight>
      </FormSection>
    </div>
  );
};

export default CompanyNameInstructionsComponent;
