import React from "react";
import classNames from "classnames";
import { IconCheckMarkComplete, IconCheckMarkNotComplete } from "../../../../ui/icons/form.icons";

const FormSectionStatus = ({ isComplete = false }: { isComplete?: boolean }) => {
  return (
    <div
      aria-label={`form section status ${isComplete ? "complete" : "not complete"}`}
      className={classNames("gx-form-section-status", isComplete && "complete")}
      style={{ marginLeft: -48, position: "absolute" }}
    >
      {isComplete ? <IconCheckMarkComplete /> : <IconCheckMarkNotComplete />}
    </div>
  );
};

export default FormSectionStatus;
