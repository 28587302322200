import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import FormSectionLeft from "./form-section-left.component";
import FormSectionRight from "./form-section-right.component";
import { formSectionStyles as styles } from "./form-section.styles";

type FormSectionProps = {
  // TODO fix conflicting linter rules between prettier/prettier wanting 4 spaces and @typescript-eslint/indent wanted 2 spaces
  children?:
    | React.ReactElement<typeof FormSectionLeft> // eslint-disable-line @typescript-eslint/indent
    | React.ReactElement<typeof FormSectionRight> // eslint-disable-line @typescript-eslint/indent
    | Array<React.ReactElement<typeof FormSectionLeft> | React.ReactElement<typeof FormSectionRight>> // eslint-disable-line @typescript-eslint/indent
    | null; // eslint-disable-line @typescript-eslint/indent
  rootClassName?: string | null;
};

const useStyles = makeStyles(() => styles);

const FormSection = ({ children = null, rootClassName = "" }: FormSectionProps) => {
  const classes = useStyles();
  return (
    <div aria-label="form section" className={classNames(classes.container, rootClassName && rootClassName)}>
      {children}
    </div>
  );
};

export default FormSection;
