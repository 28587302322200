// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RqJg0oTyKKPU5zIAwxU6{margin-top:24px}.sqySnhjXEv56iA2zmVnV{margin-top:32px;max-width:600px}.sqySnhjXEv56iA2zmVnV .r4nbJhJBJ5bA_s_YdK5i{color:var(--color-neutrals-carbon)}.sqySnhjXEv56iA2zmVnV .JQgtSGhhtGLcWJI8kgTE{margin-top:16px}.sqySnhjXEv56iA2zmVnV .JQgtSGhhtGLcWJI8kgTE .y_u7MPMeajzt4WDE6MAk{margin:12px 0 0}.w_f_AlM_j0rpj_mRDPA9{margin-top:32px}.hLyqouemb9te6Z8JNol2{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/sections/header/quick-start/sending-the-questionnaire/sending-the-questionnaire.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA,CAEA,kEACE,eAAA,CAKN,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".sendQuestionnaireImageContainer {\n  margin-top: 24px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n\n    .link {\n      margin: 12px 0 0;\n    }\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sendQuestionnaireImageContainer": `RqJg0oTyKKPU5zIAwxU6`,
	"description": `sqySnhjXEv56iA2zmVnV`,
	"heading": `r4nbJhJBJ5bA_s_YdK5i`,
	"additionalInfo": `JQgtSGhhtGLcWJI8kgTE`,
	"link": `y_u7MPMeajzt4WDE6MAk`,
	"helpLink": `w_f_AlM_j0rpj_mRDPA9`,
	"loader": `hLyqouemb9te6Z8JNol2`
};
export default ___CSS_LOADER_EXPORT___;
