import { Fragment } from "react";
import { SnackbarBanner } from "gx-npm-ui";
import { UUID } from "gx-npm-lib";
import useFormLoad from "./sections/form/context/load/use-form-load.hook";
import FooterActionBarComponent from "./sections/footer/footer-action-bar.component";
import SurveyFormComponent from "./sections/form/survey-form.component";
import HeaderComponent from "./sections/header/header.component";
import { useParams } from "react-router-dom";

const App = () => {
  const { initiativeId: newInitiativeId, surveyId: newSurveyId } = useParams();
  const { hasError, setHasError } = useFormLoad(newInitiativeId as UUID, (newSurveyId as UUID) || "");

  return (
    <Fragment>
      <HeaderComponent />
      <SurveyFormComponent />
      <FooterActionBarComponent initId={newInitiativeId as UUID} surveyId={newSurveyId as UUID} />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} />
    </Fragment>
  );
};

export default App;
