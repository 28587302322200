import { colorPalette } from "gx-npm-common-styles";

const carbonColor = colorPalette.neutrals.carbon.hex;
const ironColor = colorPalette.neutrals.iron.hex;

const formSectionAboutStyles = {
  container: { display: "flex", flexDirection: "column" as string as "column" },
  title: { color: carbonColor, paddingBottom: 12 },
  GCOM3606title: { paddingBottom: 12 },
  description: { color: ironColor, paddingBottom: 27 },
  GCOM3606description: { paddingBottom: 27 },
};

export { formSectionAboutStyles };
