import React, { useEffect, useRef, useState } from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Loader,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./overview.styles.module.scss";
import * as videoPlayer from "@vidyard/embed-code";
import { useTranslation } from "react-i18next";
import { TimerIcon } from "gx-npm-icons";
import classNames from "classnames";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const OverviewComponent = () => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const player = await videoPlayer.api.renderPlayer({
          uuid: "kaTaDgitDqmMuGEEJygJ87",
          container: videoRef.current,
        });
        player.on("ready", () => {
          setIsLoading(false);
        });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [videoRef]);

  return (
    <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
      <FeatureFlagBooleanOn>
        <div>
          <TypographyComponent styling={"h5"} boldness={"semi"}>
            {t("Overview")}
          </TypographyComponent>
          <div className={styles.mediaContainer}>
            <div ref={videoRef} className={styles.media}>
              {isLoading && (
                <div aria-label={"loader"} className={classNames(styles.loader)}>
                  <Loader size={48} />
                </div>
              )}
            </div>
            <div className={styles.mediaDesc}>
              <TypographyComponent styling={"p3"} boldness={"medium"} color={"gartnerBlue"}>
                {t("Watch a quick overview of how questionnaires work in BuySmart")}
              </TypographyComponent>
              <TypographyComponent
                rootClassName={classNames(styles.GCOM3606mediaTime)}
                styling={"p3"}
                boldness={"regular"}
                color={"iron"}
              >
                <TimerIcon className={styles.timerIcon} />
                1:29
              </TypographyComponent>
            </div>
          </div>
          <div className={styles.description}>
            <TypographyComponent color={"carbon"} styling={"p2"} boldness={"semi"}>
              {t(
                "With vendor questionnaires, you can turn requirement categories into questions and send them to your vendor contacts in order to learn more about their product."
              )}
            </TypographyComponent>
            <div className={styles.helpLink}>
              <TextLink
                text={t("Read more about vendor questionnaires")}
                target={"_blank"}
                href={
                  "https://intercom.help/gartner-buysmart-help-center/en/articles/8214167-intro-to-buysmart-questionnaires-for-evaluation-teams"
                }
              />
            </div>
          </div>
        </div>
      </FeatureFlagBooleanOn>
      <FeatureFlagBooleanOff>
        <div>
          <TypographyComponent type={"h5"} boldness={"semi"}>
            {t("Overview")}
          </TypographyComponent>
          <div className={styles.mediaContainer}>
            <div ref={videoRef} className={styles.media}>
              {isLoading && (
                <div aria-label={"loader"} className={classNames(styles.loader)}>
                  <Loader size={48} />
                </div>
              )}
            </div>
            <div className={styles.mediaDesc}>
              <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName={classNames(styles.mediaContent)}>
                {t("Watch a quick overview of how questionnaires work in BuySmart")}
              </TypographyComponent>
              <TypographyComponent rootClassName={classNames(styles.mediaTime)} styling={"p3"} boldness={"regular"}>
                <TimerIcon className={styles.timerIcon} />
                1:29
              </TypographyComponent>
            </div>
          </div>
          <div className={styles.description}>
            <TypographyComponent rootClassName={classNames(styles.heading)} styling={"p2"} boldness={"semi"}>
              {t(
                "With vendor questionnaires, you can turn requirement categories into questions and send them to your vendor contacts in order to learn more about their product."
              )}
            </TypographyComponent>
            <div className={styles.helpLink}>
              <TextLink
                text={t("Read more about vendor questionnaires")}
                target={"_blank"}
                href={
                  "https://intercom.help/gartner-buysmart-help-center/en/articles/8214167-intro-to-buysmart-questionnaires-for-evaluation-teams"
                }
              />
            </div>
          </div>
        </div>
      </FeatureFlagBooleanOff>
    </FeatureFlagBooleanContainer>
  );
};

export default OverviewComponent;
