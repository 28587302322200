import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  Button,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./dialog-cancel-setup-confirmation.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const DialogCancelSetupConfirmation = ({
  isOpen = false,
  onClose = (_isConfirmed: boolean) => {
    /* do nothing */
  },
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      body={
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p2"}>
              {t("Are you sure you want to leave your questionnaire setup? Changes that you made will not be saved.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph type="p2">
              {t("Are you sure you want to leave your questionnaire setup? Changes that you made will not be saved.")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      }
      open={isOpen}
      title={t("Leave questionnaire setup")}
      footer={
        <div className={classNames(classes.btnContainer)}>
          <Button
            onClick={() => onClose(false)}
            rootClassName={classNames("gx-btn-cancel-survey-setup-stay", "btn-tertiary")}
          >
            {t("CONTINUE EDITING")}
          </Button>
          <Button
            onClick={() => onClose(true)}
            rootClassName={classNames("gx-btn-cancel-survey-setup-leave", "primary-destructive-btn")}
          >
            {t("LEAVE SETUP")}
          </Button>
        </div>
      }
    />
  );
};

export default DialogCancelSetupConfirmation;
