import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { BaseTextArea } from "gx-npm-ui";
import { handleEvent } from "gx-npm-lib";
import { listItemTextAreaStyles as styles } from "./drag-drop-list.styles";

const useStyles = makeStyles(() => styles);

type DragDropListItemTextAreaComponentProps = {
  hover?: boolean;
  maxAllowedChars?: number;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  setEdit?: (isEdit: boolean) => void;
  showCharCountOnFocus?: boolean;
  showCharCountOnHover?: boolean;
  value: string;
  isLastItemFocused?: boolean;
};

const DragDropListItemTextAreaComponent = ({
  hover = false,
  maxAllowedChars = 0,
  onBlur,
  placeholder = "",
  setEdit,
  showCharCountOnFocus = false,
  showCharCountOnHover = false,
  value = "",
  isLastItemFocused = false,
}: DragDropListItemTextAreaComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    handleEvent(onBlur, event);
    handleEvent(setEdit, false);
  };

  const handleFocus = () => {
    handleEvent(setEdit, true);
  };
  return (
    <div aria-label={t("drag and drop textarea")} className={classes.root}>
      <BaseTextArea
        autoFocus={isLastItemFocused}
        flavor="BLUEBERRY"
        maxAllowedChars={maxAllowedChars}
        onBlur={(event: React.FormEvent<HTMLInputElement>) => handleBlur(event)}
        onFocus={() => handleFocus()}
        placeholder={placeholder}
        rootClassName={classNames("gx-drag-drop-textarea-container", hover && "parent-hover")}
        showCharCountOnFocus={showCharCountOnFocus}
        showCharCountOnHover={showCharCountOnHover}
        value={value}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleEvent(handleBlur, e);
          }
        }}
      />
    </div>
  );
};

export default DragDropListItemTextAreaComponent;
