import { useFormContext } from "./form.context";

const useFormTextFields = () => {
  const { companyName, instructions, setHasUpdates, setCompanyName, setInstructions } = useFormContext();

  const handleChangeCompanyName = (value: string) => {
    setCompanyName(value);
    setHasUpdates(true);
  };
  const handleChangeInstructions = (value: string) => {
    setInstructions(value);
    setHasUpdates(true);
  };
  return { companyName, handleChangeCompanyName, handleChangeInstructions, instructions };
};

export default useFormTextFields;
