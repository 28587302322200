import { colorPalette } from "gx-npm-common-styles";

const colorIron = colorPalette.neutrals.iron.hex;
const colorPearl = colorPalette.neutrals.pearl.hex;
const colorWhite = colorPalette.basic.white.hex;
const colorLightPear = colorPalette.status.lightPear.hex;
const colorDarkPear = colorPalette.status.darkPear.hex;
const colorSilver = colorPalette.neutrals.silver.hex;

const footerActionBarStyles = {
  root: {
    alignItems: "center",
    backgroundColor: colorWhite,
    minWidth: 1024,
    bottom: 0,
    display: "flex",
    height: 72,
    position: "fixed" as string as "fixed",
    width: "100%",
    zIndex: 1025,
    "& $right": {
      marginLeft: "auto",
    },
  },
  shadow: {
    boxShadow: `0px 0px 5px 0px ${colorSilver}`,
  },
  left: {
    marginLeft: 56,
  },
  progress: {
    alignItems: "center",
    backgroundColor: colorPearl,
    borderRadius: 20,
    display: "flex",
    height: 28,
    "& p": {
      color: colorIron,
      paddingLeft: 7,
      paddingRight: 13,
    },
    "&.complete": {
      backgroundColor: colorLightPear,
      "& p": {
        color: colorDarkPear,
      },
    },
  },
  right: {
    marginRight: 56,
  },
  save: {
    display: "inline-flex",
    marginLeft: 0,
  },
  previewBtn: {
    margin: "0 24px",
  },
  textToolTip: {
    width: "217px",
  },
};

export default footerActionBarStyles;
