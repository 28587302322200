// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yZKJ3F3vrWaip4JTyjCQ{margin-top:24px;height:216px}.CLAcgFbazjOUU_i1a0oB{margin-top:32px;max-width:600px}.CLAcgFbazjOUU_i1a0oB .ChdaU7kYYneCZKO_f3Fb{color:var(--color-neutrals-carbon)}.CLAcgFbazjOUU_i1a0oB .doSChItPgHf8ugA6m7hf{margin-top:16px}.CLAcgFbazjOUU_i1a0oB .YHwChtMZW75cGrIPwoRx{margin:12px 0 0}.BRGNRZYoMkmdaxCRjsuw{margin-top:32px}.A3V7lE9KV2oV0yH68_MD{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/sections/header/quick-start/creating-the-questionnaire/creating-the-questionnaire.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CAGF,sBACE,eAAA,CACA,eAAA,CAEA,4CACE,kCAAA,CAGF,4CACE,eAAA,CAGF,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".mediaContainer {\n  margin-top: 24px;\n  height: 216px;\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n\n  .heading {\n    color: var(--color-neutrals-carbon)\n  }\n\n  .additionalInfo {\n    margin-top: 16px;\n  }\n\n  .link {\n    margin: 12px 0 0;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediaContainer": `yZKJ3F3vrWaip4JTyjCQ`,
	"description": `CLAcgFbazjOUU_i1a0oB`,
	"heading": `ChdaU7kYYneCZKO_f3Fb`,
	"additionalInfo": `doSChItPgHf8ugA6m7hf`,
	"link": `YHwChtMZW75cGrIPwoRx`,
	"helpLink": `BRGNRZYoMkmdaxCRjsuw`,
	"loader": `A3V7lE9KV2oV0yH68_MD`
};
export default ___CSS_LOADER_EXPORT___;
