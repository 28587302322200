import { UUID } from "gx-npm-lib";
import { ResponseOptionType } from "../../form.types";
import { useFormContext } from "../form.context";
import {
  addResponseOption,
  moveResponseOptions,
  removeResponseOptionFromExistingSurvey,
  removeResponseOptionFromNewSurvey,
  updateResponseOptionName,
} from "./form-response-options.lib";

const useFormResponseOptions = () => {
  const { isExistingSurvey, responseOptions, setHasUpdates, setResponseOptions } = useFormContext();

  const handleAddResponseOption = () => {
    setResponseOptions((prev) => addResponseOption(prev));
    setHasUpdates(true);
  };

  const handleMoveResponseOption = (results: Array<ResponseOptionType>) => {
    setResponseOptions(moveResponseOptions(results));
    setHasUpdates(true);
  };

  const handleRemoveResponseOption = (id: UUID) => {
    const handler = isExistingSurvey ? removeResponseOptionFromExistingSurvey : removeResponseOptionFromNewSurvey;
    setResponseOptions((prev) => handler(prev, id));
    setHasUpdates(true);
  };

  const handleUpdateResponseOptionName = (id: UUID, name: string) => {
    setResponseOptions((prev) => updateResponseOptionName(prev, id, name));
    setHasUpdates(true);
  };

  return {
    responseOptions,
    handleAddResponseOption,
    handleMoveResponseOption,
    handleRemoveResponseOption,
    handleUpdateResponseOptionName,
  };
};

export default useFormResponseOptions;
